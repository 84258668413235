import React from "react";
import "../../styles/Page.scss";
import Layout from "../../components/Layout";
import PageInfo from "../../components/PageInfo";
import Note from "../../components/Note";

import openquery from "../../content/advanced/images/openquery.png";
import redigerquery from "../../content/advanced/images/redigerquery.png";
import suiviquery from "../../content/advanced/images/query-suivi.png";
import accesqueries from "../../content/advanced/images/acces-queries.png";
import filtres from "../../content/tutorial/images/filtres-queries.png";

export default function FeatQueries() {
  return (
    <Layout title="Queries">
      <div className="page-body">
        <PageInfo ARC ARCCo AdmLo AdmGl />
        <div className="page-title">
          <h1>Queries</h1>
        </div>
        <section>
          <h2 id="ouvrir-une-query">Ouvrir une query</h2>
          <p>
            Les <b>queries</b> sont des notes qui peuvent être ajoutées
            manuellement sur n'importe quel entrée de l'eCRF et listées dans un
            tableau que vous pourrez consulter par la suite pour en avoir une
            vue d'ensemble.
          </p>
          <p>
            Pour ouvrir une query, survolez le champ de l'eCRF souhaité, une
            bulle de dialogue apparaît alors à sa droite. Cliquez sur la bulle
            pour que l'édition de query s'ouvre.
          </p>
          <img src={openquery} alt="Ajout d'une query'" className="centered" />
          <p>
            Rédigez votre query puis cliquez sur "<b>Envoyer</b>"
          </p>
          <img
            src={redigerquery}
            alt="Rédiger une query"
            className="centered"
          />
          <Note type="tip">
            Vous pouvez améliorer votre message en appliquant du formattage.
            Pour cela il vous suffit d'entourer le texte souhaité avec les
            symboles suivants : <b>**pour mettre en gras**</b>,{" "}
            <em>_pour mettre en italique_</em> et{" "}
            <strike>~~pour barrer le texte~~</strike>.
          </Note>
          <p>
            Lorsqu'une query est ouverte, la fenêtre affiche le suivi complet de
            la query initiale et de ses réponses. Vous pouvez ainsi répondre en
            ajoutant un message supplémentaire et/ou changer le statut de la
            query.
          </p>
          <img src={suiviquery} alt="Suivi d'une query'" className="centered" />
        </section>
        <section>
          <h2 id="statut-des-queries">Statut des queries</h2>
          <p>
            Lorsque vous rédigez une query pour la première fois, son statut est
            par défaut <b>Ouverte</b> mais il est possible de le modifier par la
            suite. Pour cela ouvrez la query depuis le dossier patient puis
            cliquez sur le menu déroulant "<b>Changer le statut en ...</b>" en
            bas de la fenêtre. Les différents statuts possibles sont :
            <li>Ouverte</li>
            <li>Confirmée</li>
            <li>Résolue</li>
            <li>Invérifiable</li>
            <li>Fermée</li>
            Pour valider le changement, cliquez sur <b>Envoyer</b>. Le
            changement de statut est ajouté à l'historique de la query.
          </p>
        </section>
        <section>
          <h2 id="consulter-les-queries">Consulter les queries</h2>
          <p>
            Pour avoir une vue d'ensemble de toutes les queries de l'étude,
            rendez-vous dans le menu <b>Live</b> puis l'onglet{" "}
            <b>Liste des queries</b>.
          </p>
          <img src={accesqueries} alt="Onglet queries" className="centered" />
          <p>Cliquez sur n'importe quelle query pour consulter son contenu.</p>
          <Note type="tip">
            Pour faciliter la recherche de certaines queries particulières, vous
            pouvez filtrer les queries en cliquant sur le bouton "
            <b>Ajouter un filtre</b>" en haut à droite de l'écran. Vous pourrez
            ainsi les trier en fonction de différents critères (état, centre
            émetteur, date).
            <img src={filtres} alt="Filtres des queries" className="centered" />
          </Note>
        </section>
      </div>
    </Layout>
  );
}
